<template>
  <div
    class="p-4 d-flex flex-column justify-content-center"
    style="height: 100vh"
  >
    <b-modal
      id="chechUserVerification"
      no-close-on-backdrop
      no-close-on-keyboard
      no-close-on-esc
      hide-header-close
      hide-header
      centered
      hide-footer
      modal-no-backdrop
      hid
      size="md"
      class="bg-info"
    >
      <b-container
        ><h3 class="text-center text-dark">
          Verifying
          <b-spinner
            type="grow"
            label="Loading..."
            class="text-info"
          ></b-spinner>
        </h3>

        <h5 class="text-center">Please wait...</h5></b-container
      >
    </b-modal>
    <div v-if="!linkExpired">
      <h2 class="text-center">Logo</h2>
      <br />
      <div class="varificationPage">
        <b-container>
          <div class="emailVerify" style="border: 1px solid #d3d3d3">
            <div v-if="emailVerfied">
              <h6
                class="bg-light p-4 text-success"
                style="border-bottom-color: 1px solid #d3d3d3"
              >
                Email Verified <i class="far fa-check-circle text-success"></i>
              </h6>
              <p class="p-4 text-success" v-if="emailVerified">
                Your email is successfully verified.
              </p>
            </div>
            <div v-else>
              <h6
                class="bg-light p-4 text-success"
                style="border-bottom-color: 1px solid #d3d3d3"
              >
                Email already verified
              </h6>
            </div>
          </div>
        </b-container>
      </div>
    </div>
    <b-container v-if="linkExpired">
      <h3>The link has expired!</h3>
      <p>Please request another from your account!</p>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      emailVerfied: false,
      mobileVerified: false,
      userVerified: false,
      sendOtp: false,
      resendOtp: "",
      enteredOtp: "",
      mobileVerify: false,
      mobileVerifyFailed: false,
      otpVal: false,
      linkExpired: false,
    };
  },
  //Automatically checks the verification link is valid/not
  async mounted() {
    document.querySelector(".varificationPage").style.opacity = 0;
    this.$bvModal.show("chechUserVerification");

    const expiresSignature = this.$route.query;
    let expires = Object.keys(expiresSignature);
    let signature = Object.values(expiresSignature);
    const id = this.$route.params.id;
    const token = this.$route.params.token;
    const validData = `${id}/${token}?${expires[0]}=${signature[0]}&${expires[1]}=${signature[1]}`;

    const url = this.$store.getters.getAPIKey.verifyEmail + validData;

    try {
      const response = await axios.get(url);

      if (response.status == 202) {
        document.querySelector(".varificationPage").style.opacity = 1;
        this.$bvModal.hide("chechUserVerification");
        this.emailVerified = !this.emailVerfied;
        setTimeout(() => {
          this.$router.push("/");
        }, 3000);
      } else {
        document.querySelector(".varificationPage").style.opacity = 1;
        this.$bvModal.hide("chechUserVerification");
        setTimeout(() => {
          this.$router.push("/");
        }, 3000);
      }
    } catch (error) {
      if (error.request.status == 403) {
        this.linkExpired = !this.linkExpired;
        document.querySelector(".varificationPage").style.opacity = 1;
        this.$bvModal.hide("chechUserVerification");
        setTimeout(() => {
          this.$router.push("/");
        }, 3000);
      }
    }
  },
};
</script>

<style >
.container .verificationScreen {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verificationScreen .checkVerification {
  width: 800px;
  height: 400px;
  background-color: red;
  border-radius: 10px;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
